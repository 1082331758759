import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpParams
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { colorObj } from './shared/color-object';
import { NotificationsService } from './services/notifications.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private notify: NotificationsService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const userInfo = JSON.parse(window.localStorage.getItem('userInfo') || '{}');
    if (request.headers.get("skip") && request.headers.get("cid") == 'false') {
      return next.handle(request);
    } else if (request.headers.get("skip") && request.headers.get("cid") == 'true') {
      const newReq = request.clone({
        params: (request.params ? request.params : new HttpParams())
          .set('C_ID', JSON.parse(sessionStorage.getItem('cid')!))/*.... add new params here .....*/
      });
      return next.handle(newReq);
    }
    else {
      return next.handle(request.clone({
        setHeaders: { 'auth-token':  userInfo?.auth_token }
      }))
    }
  }
}
