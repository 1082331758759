import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private router: Router,
    public alertController: AlertController,
    private toastController: ToastController) { }

  async showNotification(
    message: string,
    verticalPosition: 'top' | 'middle' | 'bottom' = 'top',
    panelClass: 'success' | 'error' | 'warning' = 'success',
    status) {
    const toast = await this.toastController.create({
      cssClass: panelClass,
      message: message,
      duration: 2000,
      position: verticalPosition
    });
    if (status == 401) {
      await toast.present();
      window.localStorage.clear();
      this.router.navigate(['/'], { queryParams: { 'C_ID': window.sessionStorage.getItem("cid") } })
      window.location.reload();
    } else {
      await toast.present();
    }
  }
}
